import { render, staticRenderFns } from "./SectionGraphicTable.vue?vue&type=template&id=42ed48bd&"
import script from "./SectionGraphicTable.vue?vue&type=script&lang=js&"
export * from "./SectionGraphicTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ArrowIcon: require('/vercel/path0/components/icons/ArrowIcon.vue').default,Section: require('/vercel/path0/components/general/Section.vue').default})
