//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  inject: {
    container: {},
  },

  computed: {
    hasTag() {
      return this.container?.hasTag
    },
  },
}
