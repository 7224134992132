//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  computed: {
    breadcrumbs() {
      let path = this.$route.path.substring(3)
      path = path.split('/')
      path = path.filter((element) => {
        return element !== ''
      })
      return path
    },

    breadcrumbsLinks() {
      let path = this.$route.path
      path = path.split(/(?=\/)/)
      path = path.filter((element) => {
        return element !== ''
      })
      return path
    },
  },
}
