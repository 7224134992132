//
//
//
//
//
//
//
//
//

import { getEmailSettings } from '~/graphql/queries.js'

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },

    title: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      templateProduct: '',
      templateEducation: '',
    }
  },

  computed: {
    email() {
      return this.data.email || ''
    },

    subject() {
      return this.title
        ? `${
            this.$t('emailTemplate.order') +
            this.$t('emailTemplate.colons-format')
          } ${this.$route.fullPath
            .replace(/(<([^>]+)>)/gi, '')
            .replace(/&#?[a-z0-9]+;/g, '')
            .replace(/^.+\//, '')}`
        : 'Produkt bestellen Email-Vorlage'
    },

    body() {
      return this.data.product_or_education
        ? this.templateEducation
        : this.templateProduct
    },
  },

  async mounted() {
    const lang = this.$i18n.locale === 'de' ? 'de_CH' : this.$i18n.locale
    const data = await this.$graphql.cms.request(getEmailSettings, {
      lang,
    })
    console.log(data)
    this.templateProduct = data.globalSet?.text_product?.replaceAll(
      '\n',
      '%0D%0A'
    )

    this.templateEducation = data.globalSet?.text_education?.replaceAll(
      '\n',
      '%0D%0A'
    )
  },

  methods: {
    mailTo() {
      window.location.href = `mailto:${this.email}?subject=${this.subject}&body=${this.body}`
    },
  },
}
