import { render, staticRenderFns } from "./ContentBlockLoader.vue?vue&type=template&id=4f453573&"
import script from "./ContentBlockLoader.vue?vue&type=script&lang=js&"
export * from "./ContentBlockLoader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionWithTitle: require('/vercel/path0/components/contentBlock/SectionWithTitle.vue').default,SectionGraphicTable: require('/vercel/path0/components/contentBlock/SectionGraphicTable.vue').default,Container: require('/vercel/path0/components/general/Container.vue').default})
