//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { removePTags } from '~/utils'

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },

    gray: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showAll: false,
    }
  },

  computed: {
    showMoreBtn() {
      if (this.showAll) return false
      return this.data.contend.length > this.components.length
    },

    components() {
      // LIMIT AMOUNT OF SubrubricSection
      if (!this.data.contend) return []
      if (this.showAll) return this.data.contend

      let i = 0
      return this.data.contend.filter((element) => {
        if (element.type === 'subrubric_section') i++
        return i <= 3
      })
    },
  },

  methods: {
    removePTags(text) {
      return removePTags(text)
    },
  },
}
