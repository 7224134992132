import { render, staticRenderFns } from "./SectionWithTitle.vue?vue&type=template&id=f1766398&"
import script from "./SectionWithTitle.vue?vue&type=script&lang=js&"
export * from "./SectionWithTitle.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Tag: require('/vercel/path0/components/general/Tag.vue').default,List: require('/vercel/path0/components/general/List.vue').default,ContentBlockImages: require('/vercel/path0/components/contentBlock/ContentBlockImages.vue').default,NewsletterField: require('/vercel/path0/components/contentBlock/NewsletterField.vue').default,SubrubricSection: require('/vercel/path0/components/contentBlock/SubrubricSection.vue').default,VideoPlayer: require('/vercel/path0/components/general/VideoPlayer.vue').default,Pdf: require('/vercel/path0/components/general/Pdf.vue').default,FilterArrowIcon: require('/vercel/path0/components/icons/FilterArrowIcon.vue').default,Section: require('/vercel/path0/components/general/Section.vue').default})
